<!--
 Displays the island stats 
 Gets the islandCode from the route
 calls the firebase httpsCallable function "scrapfn-checkIslandStats" with the islandCode in the payload
 and returns this object:
 {
    "image": "https://cdn-0001.qstv.on.epicgames.com/YicqxKebfVqeyRhYQY/image/screen_comp.jpeg",
    "promotion_image": "https://fortnite-island-screenshots-live-cdn.ol.epicgames.com/screenshots%2F5069-7568-6802.png",
    "creator": "MochiMouse207",
    "code": "6178-2350-0998",
    "creatorCode": "mochimouse",
    "description": "Play your way from A to Z in this default Deathrun!\n16 players\nKeep an eye out for bonus items along the way\nHave fun!",
    "type": "Creative:Island",
    "title": "Alphabet Deathrun",
    "matchmaking": {
        "selectedJoinInProgressType": 979,
        "playersPerTeam": -1,
        "playerCount": 16,
        "override_Playlist": "",
        "maximumNumberOfPlayers": 16,
        "mmsType": "keep_full",
        "mmsPrivacy": "Public",
        "numberOfTeams": 16,
        "bAllowJoinInProgress": true,
        "minimumNumberOfPlayers": 16,
        "joinInProgressTeam": 255
    },
    "tags": [
        "deathrun",
        "parkour",
        "just for fun",
        "difficulty: easy"
    ],
    "latestVersion": 2,
    "xp": {
        "dataGathering": true,
        "enabled": false
    },
    "islandType": "CreativePlot:oasis_giant",
    "publishedDate": "2023-04-22T17:45:10.836Z",
    "introduction": "100 Level Default Deathrun\nPlay Your Way From A to Z\nFind Bonus Items Along The Way",
    "status": "live"
} 
Displays all this information in a nice way.
-->
<template>
    <b-card :title="island.title" :img-src="island.image" img-alt="Island Image" img-top tag="article" class="mb-2">
        <b-card-text>{{ island.description }}</b-card-text>
        <b-card-text>Creator: {{ island.creator }}</b-card-text>
        <b-card-text>Island Code: {{ island.code }}</b-card-text>
        <b-card-text>Creator Code: {{ island.creatorCode }}</b-card-text>
        <b-card-text>Tags:
            <b-badge v-for="(tag, index) in island.tags" :key="index" pill variant="primary">{{ tag }}</b-badge>
        </b-card-text>
        <b-card-text>Latest Version: {{ island.latestVersion }}</b-card-text>
        <b-card-text>XP: {{ island.xp?.enabled }}</b-card-text>
        <b-card-text>Island Type: {{ island.islandType }}</b-card-text>
        <b-card-text>Published Date: {{ new Date(island.publishedDate).toLocaleDateString() }}</b-card-text>
        <b-card-text>Status: {{ island.status }}</b-card-text>
        <b-card-text>Introduction:</b-card-text>
        <ul>
            <li v-for="(line, index) in island.introduction.split('\n')" :key="'_'+index">{{ line }}</li>
        </ul>
        <b-tabs content-class="mt-3">
            <b-tab title="Promotion Image" v-if="island.promotion_image">
                <b-card-img :src="island.promotion_image" alt="Island Promotion Image" fluid-grow="true"></b-card-img>
            </b-tab>
            <b-tab title="Matchmaking Info" v-if="island.matchmaking">
                <b-card-text>Selected Join In Progress Type: {{ island.matchmaking.selectedJoinInProgressType
                }}</b-card-text>
                <b-card-text>Players Per Team: {{ island.matchmaking.playersPerTeam }}</b-card-text>
                <b-card-text>Player Count: {{ island.matchmaking.playerCount }}</b-card-text>
                <b-card-text>MMS Type: {{ island.matchmaking.mmsType }}</b-card-text>
                <b-card-text>MMS Privacy: {{ island.matchmaking.mmsPrivacy }}</b-card-text>
                <b-card-text>Number Of Teams: {{ island.matchmaking.numberOfTeams }}</b-card-text>
                <b-card-text>Minimum Number Of Players: {{ island.matchmaking.minimumNumberOfPlayers }}</b-card-text>
                <b-card-text>Maximum Number Of Players: {{ island.matchmaking.maximumNumberOfPlayers }}</b-card-text>
                <b-card-text>Allow Join In Progress: {{ island.matchmaking.bAllowJoinInProgress }}</b-card-text>
                <b-card-text>Join In Progress Team: {{ island.matchmaking.joinInProgressTeam }}</b-card-text>
                <b-card-text>Override Playlist: {{ island.matchmaking.override_Playlist }}</b-card-text>
            </b-tab>
        </b-tabs>
    </b-card>

</template>


<script>
export default {
    props: {
        island: {
            type: Object,
            required: true,
        },
    },
};
</script>
